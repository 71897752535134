import { Controller } from "@hotwired/stimulus"
import { useIntersection } from "stimulus-use"

export default class extends Controller {
  static targets = ["video"]

  connect() {
    useIntersection(this, { threshold: [0.25, 0.5, 1.0] })
  }

  appear() {
    if (!this.#video) return
    this.#video.play()
  }

  disappear() {
    if (!this.#video) return
    this.#video.pause()
  }

  get #video() {
    return this.hasVideoTarget ? this.videoTarget : this.element
  }
}
